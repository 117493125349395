solumaxAuthClientPlugins
    .directive('tumrAccessCheck', function(
        $http) {

        return {
            template: '<span ng-show="hasAccess"><ng-transclude></ng-transclude></span>',
            restrict: "E",
            transclude: true,
            scope: {
                moduleRole: '@',
            },
            link: function(scope, elem, attrs) {

                attrs.$observe('moduleRole', function(newVal) {
                    if (newVal) {

                        $http.get('/auth-client/api/tumr/' + newVal + '/validate')
                            .then(function(res) {
                                scope.hasAccess = res.data.data
                            })
                    }
                })
            }
        }
    })