solumaxAuthClientPlugins
    .factory('TumrModel', function(
        $http) {

        var tumrModel = {}

        var base = '/auth-client/api/tumr/'

        tumrModel.validate = function(code) {
            return $http.get(base + code + '/validate')
        }

        return tumrModel
    })